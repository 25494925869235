








import { Component, Vue } from 'vue-property-decorator'
import Nav from '@/components/Nav.vue' // @ is an alias to /src
import Footer from '@/components/Footer.vue'

@Component({
  components: {
    Nav,
    Footer
  }
})
export default class App extends Vue {}
